import * as status from './status';
export * from './interfaces';
export const playable = (data) => data.game.status.id < status.ids.aborted && !imported(data);
export const isPlayerPlaying = (data) => playable(data) && !data.player.spectator;
export const isPlayerTurn = (data) => isPlayerPlaying(data) && data.game.player == data.player.color;
export const mandatory = (data) => !!data.tournament || !!data.simul || !!data.swiss;
export const playedTurns = (data) => data.game.turns - (data.game.startedAtTurn || 0);
export const bothPlayersHavePlayed = (data) => playedTurns(data) > 1;
export const abortable = (data) => playable(data) && !bothPlayersHavePlayed(data) && !mandatory(data) && !data.game.rules?.includes('noAbort');
export const rematchable = (data) => !data.game.rules?.includes('noRematch');
export const takebackable = (data) => playable(data) &&
    data.takebackable &&
    bothPlayersHavePlayed(data) &&
    !data.player.proposingTakeback &&
    !data.opponent.proposingTakeback;
export const drawable = (data) => playable(data) && data.game.turns >= 2 && !data.player.offeringDraw && !hasAi(data) && drawableSwiss(data);
export const drawableSwiss = (data) => !data.swiss || playedTurns(data) >= 60;
export const resignable = (data) => playable(data) && !abortable(data);
// can the current player go berserk?
export const berserkableBy = (data) => !!data.tournament && data.tournament.berserkable && isPlayerPlaying(data) && !bothPlayersHavePlayed(data);
export const moretimeable = (data) => isPlayerPlaying(data) &&
    data.moretimeable &&
    (!!data.clock ||
        (!!data.correspondence &&
            data.correspondence[data.opponent.color] < data.correspondence.increment - 3600));
const imported = (data) => data.game.source === 'import';
export const replayable = (data) => imported(data) || status.finished(data) || (status.aborted(data) && bothPlayersHavePlayed(data));
export function getPlayer(data, color) {
    if (data.player.color === color)
        return data.player;
    if (data.opponent.color === color)
        return data.opponent;
    return null;
}
export const hasAi = (data) => !!(data.player.ai || data.opponent.ai);
export const userAnalysable = (data) => status.finished(data) || (playable(data) && (!data.clock || !isPlayerPlaying(data)));
export const isCorrespondence = (data) => data.game.speed === 'correspondence';
export const setOnGame = (data, color, onGame) => {
    const player = getPlayer(data, color);
    onGame = onGame || !!player.ai;
    player.onGame = onGame;
    if (onGame)
        setGone(data, color, false);
};
export const setGone = (data, color, gone) => {
    const player = getPlayer(data, color);
    player.isGone = !player.ai && gone;
    if (player.isGone === false && player.user)
        player.user.online = true;
};
export const nbMoves = (data, color) => Math.floor((playedTurns(data) + (color == 'white' ? 1 : 0)) / 2);
export const isSwitchable = (data) => !hasAi(data) && (!!data.simul || isCorrespondence(data));
export const clockToSpeed = (initial, increment) => {
    const total = initial + increment * 40;
    return total < 30
        ? 'ultraBullet'
        : total < 180
            ? 'bullet'
            : total < 480
                ? 'blitz'
                : total < 1500
                    ? 'rapid'
                    : 'classical';
};
